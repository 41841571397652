import axios from 'axios'

const authApiUrl = `${process.env.VUE_APP_API_BASE_URL}/v1/api/auth`

class AuthService {
  static async login (user) {
    const response = await axios.post(`${authApiUrl}/login`, {
      email: user.email,
      password: user.password
    })
    if (response.data.accessToken) {
      window.localStorage.setItem('user', JSON.stringify(response.data))
    }
    return response.data
  }

  static logout () {
    window.localStorage.removeItem('user')
  }

  static async sendVerificationEmail (user) {
    const res = await axios.post(`${authApiUrl}/sendVerificationEmail`, {
      email: user.email,
      password: user.password,
      termsAccepted: user.termsAccepted,
      privacyPolicyAccepted: user.privacyPolicyAccepted
    })
    return res.data
  }

  static async checkEmailTokenValidity (user) {
    const { email, verificationCode } = user
    const res = await axios.post(`${authApiUrl}/checkEmailTokenValidity`, {
      email,
      verificationCode
    })
    return res.data
  }

  static async setPassword (user) {
    const res = await axios.post(`${authApiUrl}/setPassword`, {
      verificationCode: user.verificationCode,
      email: user.email,
      password: user.password,
      reset: user.reset
    })
    return res.data
  }

  static async resetPassword (email) {
    const res = await axios.post(`${authApiUrl}/forgot-password`, {
      email
    })
    return res
  }
}

export default AuthService
