import axios from 'axios'
import authHeader from '@/utils/auth-header'
const userApiUrl = `${process.env.VUE_APP_API_BASE_URL}/v1/api/users`

const userClient = axios.create({
  baseURL: userApiUrl,
  withCredentials: false,
  // headers: authHeader(), // user in localStorage is still null
})

class UserService {
  static async getProfile() {
    return userClient.get('/profile', {
      headers: authHeader(),
    })
  }

  static async editProfile(payload) {
    return userClient.post('/profile/edit', payload, {
      headers: authHeader(),
    })
  }

  static deleteProfile() {
    return userClient.get('/profile/delete', {
      headers: authHeader(),
    })
  }
}

export default UserService
