import {
  required,
  email,
  min,
  confirmed,
  double,
} from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
import i18n from './i18n'
import { MIN_AMOUNT } from '@/utils/constants'

extend('required', {
  ...required,
  message: (fieldName, _placeholders) => {
    return i18n.t('validation.required', { val: fieldName })
  },
})

extend('email', {
  ...email,
  message: i18n.tc('errors.emailInvalid'),
})

extend('min', {
  ...min,
  message: (fieldName, placeholders) => {
    return i18n.t('validation.length', {
      val: fieldName,
      length: placeholders.length,
    })
  },
})

extend('confirmed', {
  ...confirmed,
  message: (fieldName, _placeholders) => {
    return i18n.t('validation.confirmed', { val: fieldName })
  },
})

extend('double', {
  ...double,
  message: (fieldName, _placeholders) => {
    return i18n.t('errors.nanAmount', { val: fieldName })
  },
})

extend('positive', value => {
  if (+value > 0) {
    return true
  }
  return i18n.tc('errors.negativeAmount')
})

extend('min_amount', value => {
  if (+value >= MIN_AMOUNT) {
    return true
  }
  return i18n.t('validation.minAmount', { val: MIN_AMOUNT })
})
