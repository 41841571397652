<template>
  <desktop-header v-if="isDesktop" :items="navItems" />
  <mobile-header v-else :items="navItems" />
</template>

<script>
import { mapActions } from 'vuex'
import VueBreakpointMixin from 'vue-breakpoint-mixin'
import DesktopHeader from '@/components/layout/AppHeader/Desktop.vue'
import MobileHeader from '@/components/layout/AppHeader/Mobile.vue'

export default {
  name: 'AppHeader',
  components: {
    DesktopHeader,
    MobileHeader,
  },
  mixins: [VueBreakpointMixin],
  computed: {
    navItems() {
      return [
        { to: '/', title: this.$t('menu.home'), tag: 'router-link' },
        { to: 'https://blog.cryptodesk.bg/', title: this.$t('menu.blog'), tag: 'a', target: '_blank' },
        { to: 'https://help.cryptodesk.bg/ ', title: this.$t('menu.learn'), tag: 'a', target: '_blank' },
        { to: '/faq', title: this.$t('menu.faq'), tag: 'router-link' },
        { to: '/about', title: this.$t('menu.about'), tag: 'router-link' },
        { to: 'https://atm.cryptodesk.bg/', title: this.$t('menu.bitomat'), tag: 'a', target: '_blank' },
        {
          to: '/account/login',
          title: this.$t('menu.login'),
          tag: 'router-link',
          class: 'button is-light',
          signItem: true,
        },
        {
          to: '/account/register',
          title: this.$t('menu.createAccount'),
          tag: 'router-link',
          class: 'button is-primary',
          signItem: true,
        },
        {
          to: '/account',
          title: this.$t('menu.myAccount'),
          tag: 'router-link',
          class: 'button is-light',
          requiresAuth: true,
          signItem: true,
        },
        {
          to: '/',
          action: () => this.logoutAndRedirect(),
          title: this.$t('menu.logout'),
          tag: 'router-link',
          class: 'button is-primary',
          requiresAuth: true,
          signItem: true,
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
    logoutAndRedirect() {
      this.logout()
      this.$buefy.toast.open({
        duration: 7000,
        message: this.$t('dialogs.messages.logoutSuccess'),
        position: 'is-bottom',
        type: 'is-primary',
      })
      this.$router.push('/')
    },
  },
}
</script>
