export function createDebounce() {
  let timeoutID = null
  return function(fn, delay) {
    clearTimeout(timeoutID)
    const args = arguments
    const that = this
    timeoutID = setTimeout(() => {
      fn.apply(that, args)
    }, delay || 1000)
  }
}
