import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import orderPrepare from './modules/orderPrepare'
import coin from '@/store/modules/coin'
import user from '@/store/modules/user'
import orders from '@/store/modules/orders'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    coin,
    orders,
    orderPrepare,
    user,
  },
  state: {
    order: {
      type: null,
      amountGiven: null,
      addressGiven: null,
      addressReceived: null,
      cryptocurrency: null,
      beneficiary: null,
      id: null,
      user: null,
      paymentMethod: null,
      amountReceived: null,
      marketPrice: null,
      tax: null,
      easyPayTax: null,
      blockchainTax: null,
      receiverAccountId: null,
    },
    count: 0,
  },
  mutations: {
    setOrderType(state, payload) {
      state.order.type = payload
    },
    setOrderUser(state, payload) {
      state.order.user = payload
    },
    setOrderAmountGiven(state, payload) {
      state.order.amountGiven = payload
    },
    setOrderAddressGiven(state, payload) {
      state.order.addressGiven = payload
    },
    setOrderAddressReceived(state, payload) {
      state.order.addressReceived = payload
    },
    setOrderCryptocurrency(state, payload) {
      state.order.cryptocurrency = payload
    },
    setOrderBeneficiary(state, payload) {
      state.order.beneficiary = payload
    },
    setOrderId(state, payload) {
      state.order.id = payload
    },
    setPaymentMethod(state, payload) {
      state.order.paymentMethod = payload
    },
    setAmountReceived(state, payload) {
      state.order.amountReceived = payload
    },
    setMarketPrice(state, payload) {
      state.order.marketPrice = payload
    },
    setTax(state, payload) {
      state.order.tax = payload
    },
    setEasyPayTax(state, payload) {
      state.order.easyPayTax = payload
    },
    setBlockchainTax(state, payload) {
      state.order.blockchainTax = payload
    },
    setReceiverAccountId(state, payload) {
      state.order.receiverAccountId = payload
    },
  },
  actions: {
    setOrderType(context, payload) {
      context.commit('setOrderType', payload)
    },
    setOrderUser(context, payload) {
      context.commit('setOrderUser', payload)
    },
    setOrderAmountGiven(context, payload) {
      context.commit('setOrderAmountGiven', payload)
    },
    setOrderAddressGiven(context, payload) {
      context.commit('setOrderAddressGiven', payload)
    },
    setOrderAddressReceived(context, payload) {
      context.commit('setOrderAddressReceived', payload)
    },
    setOrderCryptocurrency(context, payload) {
      context.commit('setOrderCryptocurrency', payload)
    },
    setOrderBeneficiary(context, payload) {
      context.commit('setOrderBeneficiary', payload)
    },
    setOrderId(context, payload) {
      context.commit('setOrderId', payload)
    },
    setPaymentMethod(context, payload) {
      context.commit('setPaymentMethod', payload)
    },
    setAmountReceived(context, payload) {
      context.commit('setAmountReceived', payload)
    },
    setMarketPrice(context, payload) {
      context.commit('setMarketPrice', payload)
    },
    setTax(context, payload) {
      context.commit('setTax', payload)
    },
    setEasyPayTax(context, payload) {
      context.commit('setEasyPayTax', payload)
    },
    setBlockchainTax(context, payload) {
      context.commit('setBlockchainTax', payload)
    },
    setReceiverAccountId(context, payload) {
      context.commit('setReceiverAccountId', payload)
    },
  },
  getters: {
    getOrder(state) {
      return state.order
    },
    getOrderType(state) {
      return state.order.type
    },
    getOrderCrypto(state) {
      return state.order.cryptocurrency.ticker
    },
  },
})

export default store
