export const EUR_RATE = 1.95583 // EUR BGN avg exchange rate
export const TAX = 2.5 // % commission
export const MIN_TAX = 5 // 5 bgn
export const EASYPAY_SELL_TAX = 2 // 2%
export const EASYPAY_BUY_TAX = 1 // 1%
export const COINGECKO_API_URL = 'https://api.coingecko.com/api/v3/simple'
export const VUE_APP_FLOW_NAME = 'CryptoDesk'
export const VUE_APP_SUPPORT_EMAIL = 'info@cryptodesk.bg'
export const VUE_APP_SUPPORT_PHONE = '+359877750733'
export const VUE_APP_I18N_LOCALE = 'bg'
export const VUE_APP_I18N_FALLBACK_LOCALE = 'en'
export const MIN_AMOUNT = 5
export const EASYPAY_MAX = 5000
