import axios from 'axios'
import authHeader from '@/utils/auth-header'
const orderApiUrl = `${process.env.VUE_APP_API_BASE_URL}/v1/api/orders`

const orderClient = axios.create({
  baseURL: orderApiUrl,
  withCredentials: false,
})

class OrderService {
  static getOrders(params) {
    return orderClient.get('/', {
      params,
      headers: authHeader(),
    })
  }

  static sellOrder(payload) {
    return orderClient.post('/sell', payload, {
      headers: authHeader(),
    })
  }

  static buyOrder(payload) {
    return orderClient.post('/buy', payload, {
      headers: authHeader(),
    })
  }
}

export default OrderService
