<template>
  <div style="padding-bottom: 76px">
    <!-- header btns -->
    <div class="has-background-dark header-wrapper">
      <div
        class="mobile-width centered p-4 is-flex is-justify-content-space-between is-align-items-center"
      >
        <!-- back btn -->
        <div
          class="is-pointer"
          :class="{ under: hideBack }"
          @click="$router.back()"
        >
          <b-icon
            icon="chevron-left"
            pack="fas"
            class="is-size-4 pt-3 mr-2"
            type="is-light"
          ></b-icon>
        </div>
        <!-- app logo -->
        <app-logo
          class="is-pointer"
          @click="$router.push('/')"
          style="width: 164px"
          horizontal
        ></app-logo>
        <!-- menu toggle -->
        <div
          @click="toggleNavigation"
          class="is-flex is-align-items-center is-pointer"
        >
          <menu-toggle :active="toggled"></menu-toggle>
        </div>
      </div>
    </div>
    <!-- side menu -->
    <b-sidebar
      type="is-dark"
      :fullheight="true"
      :overlay="true"
      :right="true"
      v-model="toggled"
    >
      <div class="p-1">
        <b-menu>
          <b-menu-list label="">
            <b-menu-item
              v-for="(item, index) in navItems"
              :key="index"
              :label="item.title"
              :tag="item.tag"
              :to="{ path: item.to }"
              :href="item.to"
              :target="item.target"
              @click.native="onItemClick(item)"
              class="has-text-light is-size-4"
            ></b-menu-item>
          </b-menu-list>
          <b-menu-list label="">
            <b-menu-item
              v-for="(item, index) in signItems"
              :key="index"
              :label="item.title"
              :tag="item.tag"
              :to="{ path: item.to }"
              :href="item.to"
              :target="item.target"
              @click.native="onItemClick(item)"
              class="has-text-light is-size-4"
            ></b-menu-item>
          </b-menu-list>
        </b-menu>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppLogo from '@/components/ui/AppLogo'
import MenuToggle from '@/components/ui/MenuToggle'

export default {
  name: 'MobileHeader',
  components: {
    AppLogo,
    MenuToggle,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      toggled: false,
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: 'auth/loggedIn',
    }),
    navItems() {
      if (this.loggedIn) {
        return this.items.filter(item => !item.signItem)
      } else {
        return this.items.filter(item => !item.requiresAuth && !item.signItem)
      }
    },
    signItems() {
      if (this.loggedIn) {
        return this.items.filter(item => item.requiresAuth && item.signItem)
      } else {
        return this.items.filter(item => !item.requiresAuth && item.signItem)
      }
    },
    hideBack() {
      return ['/', '/intro', '/account/verify'].includes(this.$route.path)
    },
  },
  methods: {
    toggleNavigation() {
      this.toggled = !this.toggled
    },
    onItemClick(item) {
      if (item.action) {
        item.action()
      }
      this.toggleNavigation()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

.under {
  visibility: hidden;
}

.modal-background {
  position: fixed;
  z-index: 1;
}

.header-wrapper {
  position: fixed;
  width: 100%;
  z-index: 6;
}

.is-pointer {
  cursor: pointer;
}
.is-dark .menu ::v-deep .menu-label {
  color: white;
}
.is-dark .menu-list ::v-deep .has-text-light > a {
  color: white;
}

// set active items style to the default style
.is-dark .menu-list ::v-deep .has-text-light > a.is-active {
  background-color: $blue-dark;
  color: $light;
}

.is-dark .menu-list ::v-deep .has-text-light > a:hover {
  background-color: $light;
  color: $blue-dark;
}
</style>
