import UserService from '@/services/user'

export default {
  namespaced: true,
  state: {
    user: null,
  },
  getters: {
    user(state) {
      return state.user
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user || null
    },
  },
  actions: {
    async getProfile({ commit }) {
      try {
        const { data } = await UserService.getProfile()
        commit('setUser', data?.user)
      } catch (error) {
        commit('setUser', null)
        throw error
      }
    },
    editProfile(_, payload) {
      return UserService.editProfile(payload)
    },
    async deleteProfile({ commit }) {
      try {
        await UserService.deleteProfile()
        commit('setUser', null)
      } catch (error) {
        commit('setUser', null)
        throw error
      }
    },
  },
}
