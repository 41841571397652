import AuthService from '@/services/auth'

const storedUser = JSON.parse(window.localStorage.getItem('user'))
// const storedUser = undefined;
const initialState = storedUser
  ? { status: { loggedIn: true }, user: storedUser }
  : { status: { loggedIn: false }, user: null }

export default {
  namespaced: true,
  state: initialState,
  actions: {
    async login({ commit }, user) {
      try {
        const response = await AuthService.login(user)
        commit('loginSuccess', response)
        return response
      } catch (error) {
        commit('loginFailure')
        throw error
      }
    },
    async setPassword({ commit }, user) {
      try {
        const response = await AuthService.setPassword(user)
        commit('setPasswordSuccess', response)
        return response
      } catch (error) {
        commit('setPasswordFailure')
        throw error
      }
    },
    logout({ commit }) {
      AuthService.logout()
      commit('logout')
      commit('user/setUser', null, { root: true })
    },
    async sendVerificationEmail({ commit }, user) {
      try {
        const response = await AuthService.sendVerificationEmail(user)
        commit('sendVerificationMailSuccess')
        return response
      } catch (error) {
        commit('sendVerificationMailFailure')
        throw error
      }
    },
    async forgotPassword({ commit }, email) {
      try {
        const response = await AuthService.resetPassword(email)
        commit('forgotPasswordSuccess')
        return response
      } catch (error) {
        commit('forgotPasswordFailure')
        throw error
      }
    },
  },
  mutations: {
    setPasswordSuccess(state, user) {
      state.status.loggedIn = false
      state.user = user
    },
    setPasswordFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.user = null
    },
    sendVerificationMailSuccess(state) {
      state.status.loggedIn = false
      state.user = null
    },
    sendVerificationMailFailure(state) {
      state.status.loggedIn = false
    },
    forgotPasswordSuccess(state) {
      state.status.loggedIn = false
      state.user = null
    },
    forgotPasswordFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
  },
  getters: {
    loggedIn: state => {
      return state.status.loggedIn
    },
  },
}
