<template>
  <div>
    <div v-for="(item, index) in items" :key="index" class="mb-4">
      <div v-if="item.tag === 'a'">
        <a :href="item.to" :target="item.target" class="has-text-light">
          {{ item.title }}
        </a>
      </div>
      <div v-if="item.tag === 'router-link'">
        <router-link :to="item.to" class="has-text-light">{{ item.title }}</router-link>
      </div>
      <div v-if="item.tag === undefined" class="has-text-primary has-text-weight-bold">{{ item }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Links',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped></style>
