<template>
  <div>
    <div v-for="(item, index) in items" :key="index" class="mb-4">
      <div v-if="item.to">
        <a
          :href="item.to"
          target="_blank"
          class="has-text-light"
          rel="noopener noreferrer"
        >
          <div style="display: flex;">
            <b-image
              style="width: 20px"
              :src="require(`@/assets/images/${item.icon}`)"
            />
            <span class="ml-4">{{ item.title }}</span>
          </div>
        </a>
      </div>
      <div v-else class="has-text-primary has-text-weight-bold">{{ item }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Socials',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped></style>
