<template>
  <svg
    v-if="active"
    width="38"
    height="20"
    viewBox="0 0 27 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M27 0L27 23L23.8235 23L23.8235 -1.38848e-07L27 0Z" fill="white" />
    <path d="M27 0L27 23L23.8235 23L23.8235 -1.38848e-07L27 0Z" fill="white" />
    <path
      d="M15.0882 -5.2068e-07L15.0882 23L11.9118 23L11.9118 -6.59528e-07L15.0882 -5.2068e-07Z"
      fill="white"
    />
    <path
      d="M15.0882 -5.2068e-07L15.0882 23L11.9118 23L11.9118 -6.59528e-07L15.0882 -5.2068e-07Z"
      fill="white"
    />
    <path
      d="M3.17647 -1.04136e-06L3.17647 23L9.01987e-07 23L1.90735e-06 -1.18021e-06L3.17647 -1.04136e-06Z"
      fill="white"
    />
    <path
      d="M3.17647 -1.04136e-06L3.17647 23L9.01987e-07 23L1.90735e-06 -1.18021e-06L3.17647 -1.04136e-06Z"
      fill="white"
    />
  </svg>
  <svg
    v-else
    width="38"
    height="16"
    viewBox="0 0 42 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0H42V2H0V0Z" fill="white" />
    <path d="M0 0H42V2H0V0Z" fill="white" />
    <path d="M0 7.5H42V9.5H0V7.5Z" fill="white" />
    <path d="M0 7.5H42V9.5H0V7.5Z" fill="white" />
    <path d="M0 15H42V17H0V15Z" fill="white" />
    <path d="M0 15H42V17H0V15Z" fill="white" />
  </svg>
</template>

<script>
export default {
  name: 'MenuToggle',
  props: {
    active: Boolean
  }
}
</script>

<style></style>
