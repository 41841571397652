export default function authHeader() {
  let result = {}
  const user = JSON.parse(window.localStorage.getItem('user'))

  if (user?.accessToken) {
    result = { Authorization: `Bearer ${user.accessToken}` }
  }

  return result
}
