import OrdersService from '@/services/order'

export default {
  namespaced: true,
  state: {
    orders: [],
    newOrder: null,
  },
  getters: {
    orders(state) {
      return state.orders
    },
    newOrder(state) {
      return state.newOrder
    },
  },
  mutations: {
    setOrders(state, orders) {
      state.orders = orders || []
    },
    setOrderDetails(state, order) {
      state.newOrder = order || null
    },
  },
  actions: {
    async fetchOrders({ commit }, params) {
      try {
        const { data } = await OrdersService.getOrders(params)
        commit('setOrders', data?.orders)
      } catch (error) {
        commit('setOrders', null)
        throw error
      }
    },
    setOrderDetails({ commit }, payload) {
      commit('setOrderDetails', payload || null)
    },
    sellOrder(_, payload) {
      return OrdersService.sellOrder(payload)
    },
    buyOrder(_, payload) {
      return OrdersService.buyOrder(payload)
    },
  },
}
