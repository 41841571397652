<template>
  <div id="app">
    <app-header />
    <transition name="fade" mode="out-in">
      <router-view :style="isDesktop ? { padding: '0 12rem' } : ''" />
    </transition>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppHeader from '@/components/layout/AppHeader/index'
import AppFooter from '@/components/layout/AppFooter/index'
import VueBreakpointMixin from 'vue-breakpoint-mixin'

export default {
  components: {
    AppHeader,
    AppFooter,
  },
  mixins: [VueBreakpointMixin],
  computed: {
    darkBg() {
      // '/paths-with-dark-background-here'
      return [
        '/',
        '/intro',
        '/account/register',
        '/account/set-password',
        '/account/login',
        '/account/forgot-password',
        '/account/verify',
        '/sell/upload/tutorial',
      ].includes(this.$route.path)
    },
  },
  watch: {
    $route() {
      const bgClassName = this.darkBg
        ? 'has-background-dark'
        : 'has-background-light'
      document.querySelector('body').className = bgClassName
      document.querySelector('html').className = bgClassName
    },
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.1s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.control.has-icons-right .icon {
  height: 3em !important;
}

/* slide animation of field messages */
.field .help:not(.is-danger) {
  height: 18px;
  visibility: hidden;
}
.field .help.is-danger {
  height: 18px;
  animation: reveal 1s;
}
@keyframes reveal {
  from {
    transform: scale(0);
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

/* remove arrows/spinners from input type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
