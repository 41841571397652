<template>
  <b-navbar type="is-dark" transparent spaced centered>
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <app-logo style="width: 164px" horizontal></app-logo>
      </b-navbar-item>
    </template>

    <template #start>
      <b-navbar-item
        v-for="(item, index) in navItems"
        :key="index"
        :tag="item.tag"
        :to="{ path: item.to }"
        :href="item.to"
        :target="item.target"
        @click.native="!!item.action && item.action()"
      >
        {{ item.title }}
      </b-navbar-item>
    </template>

    <template #end>
      <b-navbar-item
        v-for="(item, index) in signItems"
        :key="index"
        :tag="item.tag"
        :to="{ path: item.to }"
        :href="item.to"
        :target="item.target"
        @click.native="!!item.action && item.action()"
      >
        <div class="buttons">
          <a :class="item.class">
            {{ item.title }}
          </a>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import { mapGetters } from 'vuex'
import AppLogo from '@/components/ui/AppLogo'

export default {
  name: 'DesktopHeader',
  components: {
    AppLogo,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      loggedIn: 'auth/loggedIn',
    }),
    navItems() {
      if (this.loggedIn) {
        return this.items.filter(item => !item.signItem)
      } else {
        return this.items.filter(item => !item.requiresAuth && !item.signItem)
      }
    },
    signItems() {
      if (this.loggedIn) {
        return this.items.filter(item => item.requiresAuth && item.signItem)
      } else {
        return this.items.filter(item => !item.requiresAuth && item.signItem)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.navbar.is-spaced {
  padding: 1rem 14rem;
}
.navbar-item ::v-deep img {
  max-height: 4rem !important;
}
div.navbar-item {
  cursor: pointer;
}

// active item style
// .navbar-item.router-link-active {
  // color: $primary !important;
// }

// underline on mouse hover
.navbar-start .navbar-item::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.navbar-start .navbar-item:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
</style>
