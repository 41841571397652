import CoinService from '@/services/coin'
import { EUR_RATE } from '@/utils/constants'

export default {
  namespaced: true,
  state: {
    currencies: [],
    prices: null,
  },
  getters: {
    currencies(state) {
      return state.currencies
        .sort((a, b) => a.sortNum - b.sortNum)
        .map((item, index) => ({ rank: ++index, ...item }))
    },
    currenciesWithPrices(state, getters) {
      if (!state.prices) return state.currencies
      return getters.currencies.map(item => {
        const price = state.prices[item.apiName.toLowerCase()]
        if (!price) return item
        return {
          ...item,
          price: price.eur ? price.eur * EUR_RATE : null,
          change: price.eur_24h_change || null,
          marketCap: price.eur_market_cap || null,
        }
      })
    },
    currency: state => id => {
      return state.currencies.find(item => item.id === id)
    },
  },
  mutations: {
    setCurrencies(state, currencies) {
      state.currencies = currencies || []
    },
    setPrices(state, prices) {
      state.prices = prices || null
    },
  },
  actions: {
    async fetchPrices({ commit }, params) {
      try {
        const { data } = await CoinService.getPriceCoingecko(params)
        commit('setPrices', data)
        return data
      } catch (error) {
        commit('setPrices', null)
        throw error
      }
    },
    async fetchCurrencies({ commit }) {
      try {
        const { data } = await CoinService.getAll()
        commit('setCurrencies', data)
      } catch (error) {
        commit('setCurrencies', [])
        throw error
      }
    },
    async fetchCurrency({ commit }, id) {
      const { data } = await CoinService.getById(id)
      return data
    },
  },
}
