import UserService from '@/services/user'

const initialState = () => ({
  id: null,
  amountGiven: null,
  addressGiven: null,
  addressReceived: null,
  type: null,
  description: null,
  status: null,
  beneficiary: null,
  // amount: null,
  // price: null,
  // suggestedPrice: { min: null, max: null },
  user: {
    verified: null
  },
  loading: false,
  error: null
})

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    async fetchUserType ({ commit }) {
      const userResponse = await UserService.getProfile()
      commit('userTypeReceived', userResponse.data.user.verified)
    }
  },
  mutations: {
    typeSelected (state, type) {
      state.type = type
    },
    userTypeReceived (state, value) {
      state.user.verified = value
    },
    published (state) {
      Object.assign(state, initialState())
    }
  },
  getters: {}
}
