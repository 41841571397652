import axios from 'axios'
import { COINGECKO_API_URL } from '@/utils/constants'
const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/v1/api`

const apiClient = axios.create({
  baseURL: apiUrl,
  withCredentials: false,
})

const coingeckoClient = axios.create({
  baseURL: COINGECKO_API_URL,
  withCredentials: false,
})

class CoinService {
  static async getAll() {
    return apiClient.get('/coins')
  }

  static async getById(id) {
    return apiClient.get(`/coins/${id}`)
  }

  static async getPriceCoingecko(params) {
    return coingeckoClient.get('/price', {
      params,
    })
  }
}

export default CoinService
