import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/pages/Home'),
  },
  {
    path: '/trade/:id',
    name: 'trade',
    component: () => import('@/pages/Trade'),
    children: [
      {
        path: 'sell',
        name: 'sell',
        component: () => import('@/pages/Trade'),
      },
      {
        path: 'buy',
        name: 'buy',
        component: () => import('@/pages/Trade'),
      },
    ],
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('@/pages/Order'),
    meta: { requiresAuth: true },
  },
  {
    path: '/account/login',
    name: 'Login',
    component: () => import('@/pages/account/Login'),
  },
  {
    path: '/account/register',
    name: 'Register',
    component: () => import('@/pages/account/Register'),
  },
  {
    path: '/account/set-password',
    name: 'SetPassword',
    component: () => import('@/pages/account/SetPassword'),
  },
  {
    path: '/account/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/pages/account/ForgottenPassword'),
  },
  {
    path: '/account/verify/mobile',
    name: 'VerifyMobile',
    component: () => import('@/pages/account/VerifyMobile'),
    meta: { requiresAuth: true },
  },
  {
    path: '/account/verify/web',
    name: 'VerifyWeb',
    component: () => import('@/pages/account/VerifyWeb'),
    meta: { requiresAuth: true },
  },
  {
    path: '/account',
    name: 'AccountEdit',
    component: () => import('@/pages/account/ProfileEdit'),
    meta: { requiresAuth: true },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/pages/About'),
  },
  {
    path: '/biometrics',
    name: 'Biometrics',
    component: () => import('@/pages/Biometrics'),
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('@/pages/Terms'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/pages/Privacy'),
  },
  {
    path: '/fees',
    name: 'Fees',
    component: () => import('@/pages/Fees'),
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('@/pages/FAQ'),
  },
  {
    path: '/404',
    alias: '*',
    name: 'NotFound',
    component: () => import('@/pages/NotFound'),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(_to, _from, _savedPosition) {
    return {
      x: 0,
      y: 0,
      // behavior: 'smooth',
    }
  },
})

router.beforeEach((to, _, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['auth/loggedIn']) {
      next({
        name: 'Login',
        params: { nextUrl: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

// Suppress NavigationDuplicated error - https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch(err => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

export default router
