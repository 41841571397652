import Vue from 'vue'
import axios from 'axios'
import Buefy from 'buefy'
import VueClipboard from 'vue-clipboard2'
import App from './App.vue'
import router from './router'
import store from './store'
import authInterceptor from './utils/auth-interceptor'

import './assets/scss/app.scss'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import i18n from './i18n'
import './dayjs'
import VueMeta from 'vue-meta'
import './vee-validate'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { createDebounce } from '@/utils/debounce'

axios.interceptors.response.use(undefined, authInterceptor(store, router))

Vue.use(Buefy, {
  defaultIconPack: 'fas',
})

Vue.use(VueMeta)

Vue.use(VueClipboard)

Vue.config.productionTip = false

Vue.prototype.$debounce = createDebounce()

// register validation components globally
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

const init = () => {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app')
}

document.addEventListener('deviceready', () => {
  init()
})

const isCordovaApp = typeof window.cordova !== 'undefined'
if (!isCordovaApp) {
  document.dispatchEvent(new CustomEvent('deviceready', {}))
}
