<template>
  <div class="logo is-centered" @click="$emit('click')">
    <b-image
      v-if="horizontal"
      :src="require('@/assets/images/logo_horiz.png')"
      alt="CryptoDesk Logo"
      ratio="164by44"
    />
    <b-image
      v-if="!horizontal"
      :src="require('@/assets/images/logo_big.png')"
      alt="CryptoDesk Logo"
      ratio="1by1"
    />
    <b-image
      v-if="!horizontal"
      class="logo-text has-text-centered"
      :src="require('@/assets/images/logo_text.png')"
      alt="CryptoDesk Text"
    />
  </div>
</template>

<script>
export default {
  name: 'AppLogo',
  props: {
    horizontal: Boolean
  }
}
</script>

<style lang="scss" scoped>
.logo {
  width: 100%;
}

.logo-text {
  padding: 0 4rem 0 4rem;
  width: 100%;
}

</style>
